import React, { useState } from "react"
import TextField from "@mui/material/TextField"
import Lottie from "react-lottie"
import AlfieFace from "../../assets/animation/alfie-face"
import { SegmentAnalytics } from "../../common/utils/SegmentAnalytics"
import { SegmentEvents } from "../../common/utils/SegmentSpec"
import { isValidEmail, isValidPhone } from "../../common/utils/CommonUtils"
import { BranchUtils } from "../../common/utils/BranchUtils"

const Footer = ({ data }) => {
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [emailSuccess, setEmailSuccess] = useState(false)
  const [phoneSuccess, setPhoneSuccess] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const {
    column1Links,
    column2Links,
    column3Links,
    column4Links,
    column1Title,
    column2Title,
    column3Title,
    column4Title,
  } = data

  const sendPhone = (event, phoneNumber) => {
    event.preventDefault()
    const phone = isValidPhone(phoneNumber)
    if (phone) {
      let currentPage =
        typeof window !== "undefined" ? window.location.pathname : ""
      currentPage = currentPage.replaceAll("/", "")
      if (currentPage === "") {
        currentPage = "home-page"
      }
      let fromPage = currentPage + "-download-the-app"

      SegmentAnalytics.track(SegmentEvents.USER_REQUESTED_FOR_APP_FROM_FOOTER, {
        phone,
        formLocation: fromPage,
        appRequested: true,
        category: "Get The App",
        label: "Phone",
      })
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        formLocation: fromPage,
        event: "GET_THE_APP",
        phone: phone,
      })

      BranchUtils.desktopSendSMS(phone, fromPage, "Bottom CTA", fromPage)

      setPhone("")
      setPhoneSuccess(true)
      setPhoneError(false)
    } else {
      setPhoneError(true)
      setPhoneSuccess(false)
    }
  }

  const sendEmail = (event, email) => {
    event.preventDefault()
    if (isValidEmail(email)) {
      let currentPage =
        typeof window !== "undefined" ? window.location.pathname : ""
      currentPage = currentPage.replaceAll("/", "")
      if (currentPage === "") {
        currentPage = "home-page"
      }

      SegmentAnalytics.track(
        SegmentEvents.SIGN_UP_FOR_THE_LATEST_NEWS_ARTICLES_AND_MORE,
        {
          email,
          formLocation: currentPage + "-footer",
          appRequested: true,
          category: "Newsletter",
          label: "Email",
        }
      )

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        formLocation: currentPage + "-footer",
        event: "NEW_SUBSCRIBER",
        email: email,
      })
      setEmail("")
      setEmailSuccess(true)
      setEmailError(false)
    } else {
      setEmailError(true)
      setEmailSuccess(false)
    }
  }

  let currentPathName =
    typeof window !== "undefined" ? window.location.pathname : ""
  currentPathName = currentPathName.replace("/", "")
  return (
    <div className="footer-wrapper">
      <div className="new-footer-wrapper">
        <div className="new-footer-inner">
          <div className="elfie-box">
            <div className="oval">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: AlfieFace,
                }}
                height={64}
                width={64}
              />
            </div>
          </div>
          <div className="footer-menu">
            <ul className="footer-ul">
              <p className="footer-head">{column1Title}</p>
              {column1Links.map(({ title, urlOverride }, index) => (
                <li key={index} className="footer-item">
                  <a href={urlOverride}>{title}</a>
                </li>
              ))}
            </ul>
            <ul className="footer-ul">
              <p className="footer-head">{column2Title}</p>
              {column2Links.map(({ title, urlOverride }, index) => (
                <li key={index} className="footer-item">
                  <a href={urlOverride}>{title}</a>
                </li>
              ))}
            </ul>
            <ul className="footer-ul">
              <p className="footer-head">{column3Title}</p>
              {column3Links.map(({ title, urlOverride }, index) => (
                <li key={index} className="footer-item">
                  <a href={urlOverride}>{title}</a>
                </li>
              ))}
            </ul>
            <ul className="footer-ul">
              <p className="footer-head">{column4Title}</p>
              {column4Links.map(({ title, urlOverride }, index) => (
                <li key={index} className="footer-item">
                  <a href={urlOverride}>{title}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="new-footer-number">
            <p className="btm-number-text">203.747.8696</p>
            <a
              href="http://legitscript.com/pharmacy/confidanthealth.com"
              rel="noreferrer"
              target="_blank"
              title="Verify LegitScript Approval"
            >
              <img
                className="footer-legit-img"
                src="https://static.legitscript.com/seals/5461895.png"
                alt="LegitScript approved"
                width="140"
                height="100"
                border="0"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="top-footer">
        <div className="footer-signup">
          <p className="signup-text">
            Sign up for the latest news, articles, and more!
          </p>
          <form method="post" onSubmit={(event) => sendEmail(event, email)}>
            <div className="signup-input-wrap">
              <div className="input-box">
                <TextField
                  id="email-input"
                  className="email-field"
                  placeholder="Enter your email"
                  value={email}
                  margin="normal"
                  onChange={(event) => {
                    const { value } = event.target
                    setEmail(value)
                    setEmailError(false)
                    setEmailSuccess(false)
                  }}
                  variant="standard"
                />
              </div>
              <button id="submit-email" className="subscribe-btn" type="submit">
                Subscribe
              </button>
              {emailError ? (
                <p className="error-msg">
                  Please Enter Valid Email{emailError}
                </p>
              ) : null}
              {emailSuccess ? (
                <p className="success-msg">
                  Subscribed Successfully {emailSuccess}
                </p>
              ) : null}
            </div>
          </form>
        </div>
      </div>
      <div className="bottom-footer">
        <div className="btm-inner">
          <p className="footer-text">
            &copy; Confidant Health {new Date().getFullYear()}
          </p>
          <p className="footer-text">
            2 Stony Hill Rd Unit 210 Bethel CT 06801 | 203.747.8696
          </p>
        </div>
      </div>
    </div>
  )
}
export default Footer
