import React from "react"
import { graphql } from "gatsby"
import { ThemeProvider } from "@mui/material/styles"

import SEO from "../components/seo"
import Footer from "../components/foot"
import { Navigation } from "../components/contentful"
import theme from "../theme"
import { RenderContentType } from "../components/contentful"

export default function MainPage(props) {
  const { data } = props
  const {
    pageTitle,
    keywords,
    metaTtitle,
    metaDescription,
    jsonLd,
    ...schema
  } = data?.contentfulMainPage?.seoMetadata
  const { pageSections, footer, topNavigation } = data?.contentfulMainPage

  return (
    <ThemeProvider theme={theme}>
      <Navigation data={topNavigation} />
      <SEO
        title={pageTitle || metaTtitle}
        description={metaDescription?.metaDescription}
        keywords={keywords}
        schema={schema}
      />
      {pageSections.map((section) => (
        <RenderContentType key={section.id} data={section} />
      ))}
      {Boolean(footer) && <Footer data={footer} />}
    </ThemeProvider>
  )
}

export const query = graphql`
  query PageBySlug($slug: String) {
    contentfulMainPage(slug: { eq: $slug }) {
      id
      slug
      createdAt
      seoMetadata {
        pageTitle
        metaDescription {
          metaDescription
        }
        sourceOrganization
        keywords
        timeRequired
        typicalAgeRange
        publisher
        metaTitle
        isFamilyFriendly
        isAccessibleForFree
        headline
        creator
        copyrightHolder
        contributor
        audience
        schemaVersion
        jsonLd {
          sys {
            type
          }
          internal {
            content
          }
        }
      }
      topNavigation {
        componentStyle
        mainNavigationLinks
        confidantLogo {
          componentStyle
          captionTitle
          captionSubtitle
          image {
            gatsbyImageData
            url
            file {
              url
            }
          }
          url
        }
        link5Text
        link4Text
        link3Text
        link2Text
        link1Text
        link1ExpandedNav {
          rightColumnTitle
          rightColumnLinkText
          rightColumnLinkUrl
          rightColumnLinks {
            description
            urlOverride
            title
            icon {
              filename
              file {
                url
              }
              gatsbyImageData
            }
          }
          category1Links {
            title
            urlOverride
            description
            icon {
              url
              filename
              gatsbyImageData
            }
            linkedPage {
              ... on ContentfulEducationalContent {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulLocationHub {
                id
                internal {
                  type
                }
              }
              ... on ContentfulLocationLandingPage {
                id
                internal {
                  type
                }
                serviceSlug
                stateSlug
                citySlug
              }
              ... on ContentfulMainPage {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulServiceHub {
                id
                internal {
                  type
                }
                serviceSlug
              }
              ... on ContentfulServiceLandingPage {
                id
                internal {
                  type
                }
                slug
              }
            }
          }
        }
        link2ExpandedNav {
          rightColumnTitle
          rightColumnLinkText
          rightColumnLinkUrl
          rightColumnLinks {
            description
            urlOverride
            title
            icon {
              filename
              file {
                url
              }
              gatsbyImageData
            }
          }
          category1Links {
            title
            urlOverride
            description
            icon {
              url
              filename
              gatsbyImageData
            }
            linkedPage {
              ... on ContentfulEducationalContent {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulLocationHub {
                id
                internal {
                  type
                }
              }
              ... on ContentfulLocationLandingPage {
                id
                internal {
                  type
                }
                serviceSlug
                stateSlug
                citySlug
              }
              ... on ContentfulMainPage {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulServiceHub {
                id
                internal {
                  type
                }
                serviceSlug
              }
              ... on ContentfulServiceLandingPage {
                id
                internal {
                  type
                }
                slug
              }
            }
          }
        }
        link3ExpandedNav {
          rightColumnTitle
          rightColumnLinkText
          rightColumnLinkUrl
          rightColumnLinks {
            description
            urlOverride
            title
            icon {
              filename
              file {
                url
              }
              gatsbyImageData
            }
          }
          category1Links {
            title
            urlOverride
            description
            icon {
              url
              filename
              gatsbyImageData
            }
            linkedPage {
              ... on ContentfulEducationalContent {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulLocationHub {
                id
                internal {
                  type
                }
              }
              ... on ContentfulLocationLandingPage {
                id
                internal {
                  type
                }
                serviceSlug
                stateSlug
                citySlug
              }
              ... on ContentfulMainPage {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulServiceHub {
                id
                internal {
                  type
                }
                serviceSlug
              }
              ... on ContentfulServiceLandingPage {
                id
                internal {
                  type
                }
                slug
              }
            }
          }
        }
        link4ExpandedNav {
          rightColumnTitle
          rightColumnLinkText
          rightColumnLinkUrl
          rightColumnLinks {
            description
            urlOverride
            title
            icon {
              filename
              file {
                url
              }
              gatsbyImageData
            }
          }
          category1Links {
            title
            urlOverride
            description
            icon {
              url
              filename
              gatsbyImageData
            }
            linkedPage {
              ... on ContentfulEducationalContent {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulLocationHub {
                id
                internal {
                  type
                }
              }
              ... on ContentfulLocationLandingPage {
                id
                internal {
                  type
                }
                serviceSlug
                stateSlug
                citySlug
              }
              ... on ContentfulMainPage {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulServiceHub {
                id
                internal {
                  type
                }
                serviceSlug
              }
              ... on ContentfulServiceLandingPage {
                id
                internal {
                  type
                }
                slug
              }
            }
          }
        }
        link5ExpandedNav {
          rightColumnTitle
          rightColumnLinkText
          rightColumnLinkUrl
          rightColumnLinks {
            description
            urlOverride
            title
            icon {
              filename
              file {
                url
              }
              gatsbyImageData
            }
          }
          category1Links {
            title
            urlOverride
            description
            icon {
              url
              filename
              gatsbyImageData
            }
            linkedPage {
              ... on ContentfulEducationalContent {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulLocationHub {
                id
                internal {
                  type
                }
              }
              ... on ContentfulLocationLandingPage {
                id
                internal {
                  type
                }
                serviceSlug
                stateSlug
                citySlug
              }
              ... on ContentfulMainPage {
                id
                internal {
                  type
                }
                slug
              }
              ... on ContentfulServiceHub {
                id
                internal {
                  type
                }
                serviceSlug
              }
              ... on ContentfulServiceLandingPage {
                id
                internal {
                  type
                }
                slug
              }
            }
          }
        }
        navButton {
          buttonText
          buttonUrl
        }
        phoneNumber
      }
      pageSections {
        ... on ContentfulHero {
          id
          internal {
            type
          }
          logos {
            captionTitle
            captionSubtitle
            image {
              file {
                url
              }
              filename
              gatsbyImageData
            }
            url
          }
          bulletPoints {
            description {
              raw
            }
            title {
              title
            }
          }
          componentStyle
          backgroundColor
          placeholder
          textBelowInput
          title
          subtitle
          description {
            description
          }
          primaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          secondaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          supportingImages {
            id
            file {
              url
            }
            gatsbyImageData
          }
        }
        ... on ContentfulAccordianOrFaq {
          id
          internal {
            type
          }
          name
          componentStyle
          contentAlignment
          backgroundColor
          title
          description {
            description
          }
          image {
            filename
            file {
              url
            }
            gatsbyImageData
          }
          subtitle
          primaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          secondaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          accordionItems {
            title {
              title
            }
            description {
              raw
            }
          }
        }
        ... on ContentfulCtaBlocks {
          id
          internal {
            type
          }
          componentStyle
          contentAlignment
          backgroundColor
          ctaDescription: description
          title
          subtitle
          image {
            file {
              url
            }
            gatsbyImageData
          }
          placeholderText
          textBelowEmailInput
          iconsAndButtons {
            ... on ContentfulImagesWithLinks {
              id
              internal {
                type
              }
              componentStyle
              image {
                file {
                  url
                }
                gatsbyImageData
                url
              }
              url
            }
            ... on ContentfulButton {
              id
              internal {
                type
              }
              buttonText
              buttonUrl
              openMethod
            }
          }
        }
        ... on ContentfulDynamicContentSection {
          id
          internal {
            type
          }
          componentStyle
          backgroundColor
          tiitle
          subtitle
          description {
            description
          }
          contentSections {
            ... on ContentfulGridItem {
              id
              internal {
                type
              }
              title
              description {
                description
              }
              icon {
                file {
                  url
                }
                gatsbyImageData
                filename
              }
              image {
                file {
                  url
                }
                gatsbyImageData
                filename
              }
              linkUrl
              linkText
            }
            ... on ContentfulOldContentSection {
              id
              internal {
                type
              }
              alignment
              backgroundColor
              componentStyle
              contentAlignment
              description {
                description
              }
              linkText
              link
              richText {
                raw
              }
              links {
                title
                urlOverride
              }
              primaryButton {
                buttonText
                buttonUrl
                openMethod
              }
              subtitle
              heading
              image {
                file {
                  url
                }
                filename
                gatsbyImageData
              }
              supportingImages {
                file {
                  url
                }
                filename
                url
                gatsbyImageData
              }
              photostack {
                filename
                url
                file {
                  url
                  fileName
                }
                gatsbyImageData
              }
              image {
                file {
                  url
                  fileName
                }
                gatsbyImageData
              }
            }
          }
        }
        ... on ContentfulGrid {
          id
          internal {
            type
          }
          backgroundColor
          componentStyle
          gridItems {
            ... on ContentfulEducationalContent {
              id
              internal {
                type
              }
              authorName
              authorOrganization
              edDescription: description
              content {
                raw
              }
              authorImage {
                file {
                  url
                }
                gatsbyImageData
              }
              titleImage {
                file {
                  url
                }
                gatsbyImageData
              }
              title {
                title
              }
            }
            ... on ContentfulImagesWithLinks {
              id
              internal {
                type
              }
              componentStyle
              image {
                file {
                  url
                }
                gatsbyImageData
              }
              url
              captionTitle
              captionSubtitle
            }
            ... on ContentfulGridItem {
              id
              internal {
                type
              }
              linkUrl
              linkText
              description {
                description
              }
              gridTitle: title
              icon {
                file {
                  url
                }
                filename
                gatsbyImageData
              }
              image {
                file {
                  url
                }
                filename
                gatsbyImageData
              }
            }
          }
        }
        ... on ContentfulInterstitials {
          id
          internal {
            type
          }
          backgroundColor
          componentStyle
          linkUrl
          linkText
          primaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          secondaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          text
        }
        ... on ContentfulOldContentSection {
          id
          internal {
            type
          }
          alignment
          contentAlignment
          backgroundColor
          componentStyle
          description {
            description
          }
          heading
          link
          linkText
          richText {
            raw
          }
          links {
            title
            urlOverride
          }
          primaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          secondaryButton {
            buttonText
            buttonUrl
            openMethod
          }
          subtitle
          videoUrl
          supportingImages {
            file {
              url
            }
            filename
            gatsbyImageData
          }
          image {
            file {
              url
              fileName
            }
            gatsbyImageData
          }
          supportingItems {
            ... on ContentfulImagesWithLinks {
              id
              internal {
                type
              }
              captionTitle
              captionSubtitle
              componentStyle
              url
              image {
                file {
                  url
                  fileName
                }
                gatsbyImageData
              }
            }
            ... on ContentfulGridItem {
              id
              internal {
                type
              }
              linkUrl
              linkText
              description {
                description
              }
              image {
                file {
                  url
                }
                filename
                gatsbyImageData
                description
              }
              icon {
                file {
                  url
                }
                gatsbyImageData
                filename
              }
              heading: title
            }
            ... on ContentfulFaqStatsAccordionItems {
              id
              internal {
                type
              }
              description {
                raw
              }
              title {
                title
              }
            }
          }
        }
        ... on ContentfulOldHeaders {
          id
          internal {
            type
          }
          componentStyle
          subtitle
          title
          imageOrVideo {
            file {
              url
            }
            gatsbyImageData
          }
          description {
            description
          }
          featuredIcons {
            file {
              url
            }
            gatsbyImageData
          }
        }
        ... on ContentfulCarousels {
          id
          internal {
            type
          }
          backgroundColor
          articles {
            authorImage {
              filename
              file {
                url
              }
              gatsbyImageData
            }
            authorName
            authorOrganization
          }
          componentStyle
          explanationCards {
            captionSubtitle
            captionTitle
          }
        }
        ... on ContentfulContentSection {
          id
          internal {
            type
          }
          subtitle
          tiitle
        }
        ... on ContentfulSectionHeader {
          id
          internal {
            type
          }
          title
          backgroundColor
          subtitle
          componentStyle
        }
        ... on ContentfulOldSectionHeaders {
          id
          internal {
            type
          }
          name
          backgroundColor
          componentStyle
        }
        ... on ContentfulQuotesAndTestimonials {
          id
          internal {
            type
          }
          backgroundColor
          componentStyle
          testimonials {
            backgroundColorCode
            description
            name
          }
        }
        ... on ContentfulForm {
          id
          internal {
            type
          }
          componentStyle
          formDescription: description
          subtitle
          title
          embeddedFormUrl
          supportingContent {
            raw
          }
        }
        ... on ContentfulFeaturedIn {
          id
          internal {
            type
          }
          componentStyle
          backgroundColor
          title
          imagesWithLinks {
            url
            image {
              filename
              file {
                url
              }
              gatsbyImageData
            }
            captionTitle
            captionSubtitle
          }
        }
        ... on ContentfulTabbedContentSection {
          id
          internal {
            type
          }
          componentStyle
          column4Title
          column3Title
          column2Title
          column1Title
          backgroundColor
          title
          description {
            description
          }
          column1Content {
            alignment
            description {
              description
            }
            heading
            subtitle
            image {
              url
              filename
              gatsbyImageData
            }
          }
          column2Content {
            alignment
            description {
              description
            }
            heading
            subtitle
            image {
              url
              filename
              gatsbyImageData
            }
          }
          column3Content {
            alignment
            description {
              description
            }
            heading
            subtitle
            image {
              url
              filename
              gatsbyImageData
            }
          }
          column4Content {
            alignment
            description {
              description
            }
            heading
            subtitle
            image {
              url
              filename
              gatsbyImageData
            }
          }
        }
      }
      footer {
        column1Links {
          title
          urlOverride
        }
        column1Title
        column2Links {
          urlOverride
          title
        }
        column2Title
        column3Links {
          title
          urlOverride
        }
        column3Title
        column4Links {
          urlOverride
          title
        }
        column4Title
        backgroundColor
        title
        description {
          description
        }
        copyrightText
        linksTitle
      }
    }
  }
`
